/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'
import { Icon } from '@iconify/react'
import parse from 'html-react-parser'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import WorkList from "./workList"
// import CarouselList from "../../../../common/components/banner/CarouselList"

export default function Work() {
    const navigate = useNavigate()
    const { width } = useDimensions()
    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 68,
                paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? 0 : 0),
                paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? 12 : 12),
                backgroundColor:"#EEEEEE"
            }}
        >
            <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 26 }}>ผลงานที่ผ่านมา</label>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            paddingTop: 24,
                            paddingBottom: 0
                        }}
                    >
                        <WorkList />
                    </div>
                </Col>
            </Row>
        </div>
    )
}