/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Row, Col, Card } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { padding } from '@mui/system'
// import iconCheck from 'public/assets/images/icon/check-mark.png';

export default function OurServices() {

    const { width } = useDimensions()


    return (
        <div>
            <div style={{ backgroundColor: "white", borderRadius: 8, color: "white" }}>
                <Row
                    gutter={[(width > 430 ? 12 : 0), 24]}
                    style={{
                        paddingTop: 84,
                        paddingBottom: 84,
                        paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? "7%" : 24),
                        paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? "7%" : 24),
                        backgroundColor: "#FFFFFF"
                    }}
                >
                    <Col span={24} style={{ textAlign: "center", color: "black", justifyContent: "space-between" }}>
                        <label style={{ fontSize: 26 }}>บริการของเรา</label>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Card style={{ width: "100%", height: "138px", borderRadius: 10, backgroundColor: 'transparent', border: 'none' }}>
                            <Card.Grid style={{
                                background: '#D9D9D9',
                                width: '100%',
                                fontSize: 18,
                                textAlign: 'center',
                                borderRadius: '8px',
                                padding: '0px'

                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "flex-start",
                                    alignItems: 'center',
                                }}>
                                    <div style={{ background: "#FF7A00", borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                                        <img src=".\assets\images\our_service\our_service_a.png" width={128} height={128} />
                                    </div>

                                    <div style={{ textAlign: 'left', justifyContent: 'center', display: "flex", flexDirection: 'column', paddingLeft: 14, paddingRight: 8 }}>
                                        <strong style={{ fontSize: 18, marginBottom: 8 }}>
                                            งานรถแม็คโคร
                                        </strong>
                                        <div style={{ fontSize: 16 }}>
                                            บริการรถแม็คโครพร้อมคนขับมืออาชีพรับงานในไซต์งานก่อสร้างทุกประเภท
                                        </div>
                                    </div>
                                </div>
                            </Card.Grid>
                        </Card>
                        <div style={{ display: "grid", color: "black", marginTop: 16, paddingLeft: 12 }}>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานเคลียริ่งพื้นที่รกร้าง</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานรื้อถอนบ้านอาคาร</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานถมที่</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานสกัดพื้นปูน</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานขุดฟุตติ้ง</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานขุดร่องสวน</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานกดเข็ม</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>รับงานปรับหน้าดินทั่วไป</label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Card style={{ width: "auto", height: "138px", borderRadius: 10, backgroundColor: 'transparent', border: 'none' }}>
                            <Card.Grid style={{
                                background: '#D9D9D9',
                                width: '100%',
                                fontSize: 18,
                                textAlign: 'center',
                                borderRadius: '8px',
                                padding: '0px'

                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "flex-start",
                                    alignItems: 'center',

                                }}>
                                    <div style={{ background: "#FF7A00", borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                                        <img src=".\assets\images\our_service\our_service_b.png" width={128} height={128} />
                                    </div>

                                    <div style={{ textAlign: 'left', justifyContent: 'center', display: "flex", flexDirection: 'column', paddingLeft: 14, paddingRight: 8 }}>
                                        <strong style={{ fontSize: 18, marginBottom: 8 }}>
                                            งานรถบรรทุกดิน
                                        </strong>
                                        <div style={{ fontSize: 16 }}>
                                            <label>รถบรรดิน หรือสิ่งของทุกอย่าง ส่งเร็ว บริการไว พร้อมคนขับมืออาชีพ</label>
                                        </div>
                                    </div>
                                </div>

                            </Card.Grid>
                        </Card>
                        <div style={{ display: "grid", color: "black", marginTop: 16, paddingLeft: 12 }}>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ส่งหิน</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ส่งทราย</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ส่งลูกรัง</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ส่งหินคลุก</label>
                            </div>
                        </div>
                    </Col>
                    
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Card style={{ width: "auto", height: "138px", borderRadius: 10, backgroundColor: 'transparent', border: 'none' }}>
                            <Card.Grid style={{
                                background: '#D9D9D9',
                                width: '100%',
                                fontSize: 18,
                                textAlign: 'center',
                                borderRadius: '8px',
                                padding: '0px'

                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "flex-start",
                                    alignItems: 'center',

                                }}>
                                    <div style={{ background: "#FF7A00", borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                                        <img src=".\assets\images\our_service\our_service_c.png" width={128} height={128} />
                                    </div>
                                    <div style={{ textAlign: 'left', justifyContent: 'center', display: "flex", flexDirection: 'column', paddingLeft: 14, paddingRight: 8 }}>
                                        <strong style={{ fontSize: 18, marginBottom: 8 }}>
                                            ให้เช่ารถ
                                        </strong>
                                        <div style={{ fontSize: 16 }}>
                                            บริการให้เช่ารถแม็คโครทั้งรายวัน/รายเดือนพร้อมคนขับมืออาชีพ
                                        </div>
                                    </div>
                                </div>
                            </Card.Grid>
                        </Card>
                        <div style={{ display: "grid", color: "black", marginTop: 16, paddingLeft: 12 }}>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ให้เช่ารถแม็คโคร pc30-118</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ให้เช่ารถ 6 ล้อ 3 คิว</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <img src=".\assets\images\icon\check-mark.png" width={21} height={21} />
                                <label style={{ fontSize: 16, paddingLeft: 8 }}>ให้เช่ารถ 6 ล้อ 5 คิว</label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
