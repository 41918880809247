import "./styles/App.css"
import "./styles/App.less"

import Home from "./modules/home"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
// import Product from "./modules/product"
import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home authorized={authorized} firebase={firebase} user={user} />} />
                {/* <Route exact path="/product" element={<Product authorized={authorized} firebase={firebase} user={user} />} /> */}
                <Route exact path="/login-office" element={<LoginERP authorized={authorized} firebase={firebase} user={user} />} />
                <Route exact path="/back-office" element={<ERP authorized={authorized} firebase={firebase} user={user} />} />
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
