import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getProductBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PRODUCT_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertProductFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_PRODUCT_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateProductByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PRODUCT_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteProductByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_PRODUCT_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getProductBySearchFetch,

    // insert
    insertProductFetch,

    // update
    updateProductByIdFetch,

    // delete
    deleteProductByIdFetch
}
