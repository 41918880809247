/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Pagination, Button } from 'antd'
import { Icon } from '@iconify/react'
import Card from '../../../../../common/components/card'
import { getWorkAllFetch } from '../../../../product/API'
import LoadingPage from '../../../../../common/components/loading/page'

export default function WorkList(props) {

    const [work, setWork] = useState([])
    const [loading, setLoading] = useState(false)

    const size = 12

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: size
    })

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: size
            });
        } else {
            setPage({
                minValue: (value - 1) * size,
                maxValue: value * size
            })
        }
    }

    const getWorkAll = async () => {
        // let result = await getWorkAllFetch()
        // console.log("getWorkAllFetch : ", result)
        // if (result) {
            let obj = [
                {
                    workId: 1,
                    imageURL: "./assets/images/previous-work/previous-work1.jpeg"
                },
                {
                    workId: 2,
                    imageURL: "./assets/images/previous-work/previous-work2.jpeg"
                },
                {
                    workId: 3,
                    imageURL: "./assets/images/previous-work/previous-work3.jpeg"
                },
                {
                    workId: 4,
                    imageURL: "./assets/images/previous-work/previous-work4.jpeg"
                },
                {
                    workId: 5,
                    imageURL: "./assets/images/previous-work/previous-work5.jpeg"
                },
                {
                    workId: 6,
                    imageURL: "./assets/images/previous-work/previous-work6.jpeg"
                },
                {
                    workId: 7,
                    imageURL: "./assets/images/previous-work/previous-work7.jpeg"
                },
                {
                    workId: 8,
                    imageURL: "./assets/images/previous-work/previous-work8.jpeg"
                },
                {
                    workId: 9,
                    imageURL: "./assets/images/previous-work/previous-work9.jpeg"
                }
            ]
            setWork(obj)
        // }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getWorkAll()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <div style={{ borderRadius: 8 }}>
                <Row>
                    {!loading ?
                        <>
                            <Col span={24}>
                                <Row>
                                    {work
                                        ?.slice(page.minValue, page.maxValue)
                                        ?.map((item, index) => {
                                            return (
                                                <Col xs={24} sm={8} md={8} lg={8}>
                                                    <Card
                                                        key={index}
                                                        index={index + 1}
                                                        workId={item?.workId}
                                                        // title={item?.title}
                                                        imageURL={item?.imageURL}
                                                        // subTitle={item?.subTitle}
                                                        // height={240

                                                        isPaddingLeft={true}
                                                        isPaddingRight={true}
                                                        isPaddingTop={true}
                                                        
                                                    />
                                                    

                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>

                            <Col span={24} style={{ paddingTop: work?.length > 12 ? 12 : 0, paddingBottom: 12, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                {work?.length > size ?
                                    <center>
                                        <Pagination
                                            defaultCurrent={1}
                                            defaultPageSize={size}
                                            onChange={handleChange}
                                            total={work?.length ?? 0}
                                        />
                                    </center>
                                    : []
                                }

                                {/* <Button
                                        type="primary"
                                        onClick={() => {

                                        }}
                                    >
                                        <label>ดูเพิ่มเติม</label>
                                    </Button> */}
                            </Col>
                        </>
                        :
                        <Col span={24}>
                            <LoadingPage loading={loading} />
                        </Col>
                    }

                </Row>
            </div>
        </>
    )
}
