/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Modal
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function Contact() {
    const { width } = useDimensions()

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 84,
                paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                backgroundColor: "white"
            }}
        >
            <Row gutter={[24, 32]}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 26 }}>ติดต่อ</label>
                </Col>

                <Col xs={24} md={12} xl={12}>
                    <div style={{ display: 'grid', alignItems: 'center', justifyContent: "center" }}>
                        <label style={{ paddingTop: 12, fontSize: 20 }}><strong>อภิวัฒน์บริการ</strong></label>

                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <label style={{ fontSize: 18 }}>ลาดพร้าว 101 โพธิ์แก้ว 3 แยก 11 กรุงเทพมหานคร 10240</label>
                        </div>
                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <div>
                                <img src='.\assets\images\contact\phone.png' style={{ width: 21, height: 21 }}></img>
                            </div>
                            <label style={{ paddingLeft: 8, fontSize: 16 }}><a href="tel:+0653875919" style={{ color: "inherit" }} >065-387-5919 (คุณเบียร์)</a> </label>
                        </div>
                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <div>
                                <img src='.\assets\images\contact\phone.png' style={{ width: 21, height: 21 }}></img>
                            </div>
                            <label style={{ paddingLeft: 8, fontSize: 16 }}><a href="tel:+0935676680" style={{ color: "inherit" }}>093-567-6680 (เอิร์น)</a></label>
                        </div>

                        <label style={{ paddingTop: 12 }}></label>
                        <label style={{ paddingTop: 12 }}></label>
                        <label style={{ paddingTop: 12 }}></label>
                        <label style={{ paddingTop: 12 }}></label>
                    </div>
                </Col>
                <Col xs={24} md={12} xl={12}>
                    {/* <img
                        style={{ objectFit: "cover", width: "100%", height: 240, borderRadius: 8, }}
                        src={`./assets/images/contact/address.png`}
                    /> */}

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.6022232656205!2d100.62617797430137!3d13.802836386594384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d627245f523a3%3A0x750b6fba81af56c1!2s101%20Soi%20Pho%20Kaeo%203%2C%20Lane%2011%2C%20Khwaeng%20Khlong%20Chan%2C%20Khet%20Bang%20Kapi%2C%20Krung%20Thep%20Maha%20Nakhon%2010240!5e0!3m2!1sen!2sth!4v1712808795563!5m2!1sen!2sth"  style={{ border: 0, width: "100%", height: 240 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Col>
            </Row>
        </div>
    )
}