import React, { useState } from 'react'
import {
    Row,
    Col,
    Button,
    Card
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"
import { FaPhoneAlt } from "react-icons/fa";
const gridStyle = {
    width: '25%',

};

export default function Main() {

    const { width } = useDimensions()

    return (
        <>
            <div
                style={{
                    backgroundImage: "url(./assets/images/background/company-building.jpg)",
                    backgroundSize: (width > 760 ? '100%' : '255%'),
                    width: '100% auto',
                    height: '100% auto',
                    alignItems: "center",
                    filter: "brightness(110%)",
                }}
            >
                <div
                    style={{
                        // display: "flex",
                        justifyContent: width > 760 ? "space-between" : "center",
                        paddingTop: width > 1200 ? 110 : (width > 760 && width < 1200 ? 110 : 30),
                        paddingBottom: 42,
                        paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                        paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24)
                    }}
                >
                    <div
                        style={{
                            // display: "grid",
                            // backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            // borderRadius: 16,
                            color: "white",
                            // width: 886,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 134,
                            paddingBottom:  (width > 1200 ? 150 : 110)
                        }}
                    >
                        <label style={{ fontSize: (width > 430 ? 48 : 32) }}>
                            <strong>บริการ รถแม็คโคร รถบรรทุกดิน เเละให้เช่ารถ</strong>
                            <div></div>
                        </label>
                        <div>
                            <label style={{ fontSize: (width > 430 ? 40 : 26) }}>
                                รับงานเขตกรุงเทพฯและปริมณฑล
                            </label>
                        </div>

                        <div style={{ display: (width > 599 ? "flex" : "grid"), alignItems: "center", justifyContent: "flex-start", paddingTop: 6 }}>
                            <div>
                                <a href="tel:+0653875919" style={{ color: "inherit" }}>
                                    <Button type="primary" size="large">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {/* <Icon icon="dashicons:admin-customizer" style={{ color: "white" }} /> */}
                                            <FaPhoneAlt />
                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>065-387-5919 (คุณเบียร์)</label>
                                        </div>
                                    </Button>
                                </a>
                            </div>

                            <div style={{ paddingLeft: (width > 599 ? 20 : 0), paddingTop: (width > 599 ? 0 : 20) }}>
                                <a href="tel:+0935676680" style={{ color: "inherit" }}>
                                    <Button type="primary" size="large">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {/* <Icon icon="dashicons:admin-customizer" style={{ color: "white" }} /> */}
                                            <FaPhoneAlt />
                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>093-567-6680 (เอิร์น)</label>
                                        </div>
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}